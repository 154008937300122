import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";

//

const LiveChat = (props) => {
  const { rpdata } = useContext(GlobalDataContext);

  return (
    <div>
<div class="elfsight-app-092e4d91-d551-44a2-8cf9-005226dd9c58"></div>
      <div className="visor_Counter">
<div class="elfsight-app-b8a56f1e-13f0-420d-a542-091d4e218b2d"></div>
      </div>
    </div>
  );
};

export default LiveChat;
